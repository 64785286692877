@import '../../base/variables';
@import '../../base/mixins';

.terms-of-service-header {
  display: flex;
  align-items: center;
  background-color: $cloudy-blue;
}

.terms-of-service {
  &__description {
    flex: 1;
    text-align: center;

    &-block {
      display: flex;
      align-items: center;
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 24px;
    padding-bottom: 48px;

    @include screen-from(md) {
      align-items: center;
      padding-top: 64px;
      padding-bottom: 64px;
    }

    @include screen-from(xl) {
      padding-top: 68px;
      padding-bottom: 96px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;

    @include screen-from(md) {
      flex-direction: row;
      gap: 70px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 36px;
      padding-top: 40px;
      padding-bottom: 140px;

      @include screen-from(md) {
        padding-top: 84px;
        gap: 0;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include screen-from(md) {
        gap: 0;
      }
    }
  }

  &__article {
    flex: 0.5;

    &-text {
      margin-bottom: 16px;
    }

    &_right {
      &-text {
        font-weight: $font-weight-bold;
      }

      &-link {
        font-weight: $font-weight-normal;
      }

      &-list {
        font-weight: $font-weight-bold;
      }
    }

    &-title {
      text-align: left;
      margin: 0;

      @include screen-from(md) {
        text-align: center;
        margin-top: 84px;
        margin-bottom: 40px;
      }
    }
  }
}
